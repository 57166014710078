import { Text } from "@mantine/core";
import { vars } from "~/config/theme";

export function ErrorText({
  children,
  textAlign = "left",
}: {
  children: React.ReactNode;
  textAlign?: "center" | "left" | "right";
}) {
  if (!children) return null;
  return (
    <Text
      size="xs"
      style={{
        color: vars.colors.error,
        textAlign: textAlign,
        marginTop: `calc(${vars.spacing.xs} / 2) `,
      }}
    >
      {children}
    </Text>
  );
}
